@import "_bootstrap-sass-grid";
@import "libs/fonts";
@import "libs/icons";
@import "libs/magnificPopup.css";
@import "libs/owl.carousel.min.css";

body {
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	ul.default{
		list-style: none;
		-webkit-margin-before: 0;
		-webkit-margin-after: 0;
		-webkit-margin-start: 0;
		-webkit-margin-end: 0;
		-webkit-padding-start: 0;
	}
	.img-responsive{
		display: block;
		max-width: 100%;
		min-width: 100%;
		height: auto;
	}
	.img-fluid {
		max-width: 100%;
		height: auto;
	}
	.text-center{
		text-align: center;
	}
	.p-t-15{
		padding-top: 15px;
	}
	.p-b-15{
		padding-bottom: 15px;
	}
	.p-t-30{
		padding-top: 30px;
	}
	.p-b-30{
		padding-bottom: 30px;
	}
	.p-b-120{
		padding-bottom: 120px;
	}
	.noPadding{
		padding: 0px;
	}
	%img-responsive{
		width: 100%;
		height: auto;
	}
	.photo{
		display: block;
		padding-top: 5px;
		padding-bottom: 5px;
		img{
			@extend %img-responsive;
		}
	}
	.price{
		font-size: 20px;
		color: #006E2D;
		font-weight: bold;
		img{
			max-width: 250px;
			margin-left: auto;
			margin-right: auto;
		}
		strong{
			font-size: 18px;
			color: black;
		}
	}
	.withBg{
		padding-bottom: 40px;
		background-image: url(../images/withBg.jpg);
		background-repeat: no-repeat;
		background-position: right bottom;

		@media screen and (max-width:960px) {
			text-align: center;
		}
		ul{
			list-style: none;
			-webkit-padding-start: 0px;
			li{
				padding-left: 25px;
				margin-bottom: 10px;
				&::before{
					content: "";
					position: absolute;
					width: 10px;
					height: 10px;
					display: block;
					border-radius: 10px;
					background-color: #006E2D;
					margin-left: -20px;
					margin-top: 7px;
				}
			}
		}
		.cont{
			display: block;
			font-size: 18px;
			font-weight: bold;
			text-decoration: none;
			padding-top: 10px;
			padding-bottom: 10px;
			color: #006E2D;
			border-bottom: none;
			i{
				margin-right: 10px;
			}
		}
	}
	.greenBg{
		background-color: #006E2D;
		width: 100%;
		padding-top: 60px;
		padding-bottom: 60px;
	}
	.row.display-flex {
		display: flex;
		flex-wrap: wrap;
		&::before{
			content: "";
			display: none;
		}
	}
	.row.display-flex > [class*='col-'] {
		display: flex;
		flex-direction: column;
		float: none;
	}
	.col-akce{
		@extend .col-sm-6;
		margin-bottom: 20px;
		display: flex;
		flex-direction: column;
		&__item{
			display: block;
			box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);
			padding: 10px;
			text-decoration: none;
			font-size: 14px;
			color: black;
			line-height: 22px;
			flex: 1 0 100%;
			background-color: white;
			a:not(.photo) {
				color: #006E2D;
				text-decoration: none;
				border-bottom: 1px solid #006e2d;
			}
			img{
				@extend .pull-left;
				max-width: 200px !important;
				margin-right: 20px;
			}
			small{
				color: #cd9b3c;
				font-weight: bold;
				font-size: 13px;
				padding-bottom: 10px;
				display: block;
				margin: 0px;
			}
			big{
				display: block;
				padding-top: 5px;
				font-weight: bold;
				color: #006E2D;
				font-size: 20px;
			}
			.mark{
				font-size: 12px;
				background-color: rgba(0,0,0,.3);
				padding: 4px;
				color: white;
				border-radius: 8px;
				margin: 0px;
				&::after{
					content: "\A";
					white-space: pre;
				}
			}
		}
		@media screen and (max-width:980px) {
			&{
				a{
					img{
						float: none;
						width: 100%;
						min-width: 100%;
						margin-bottom: 10px;
					}
				}
			}
		}
	}
	.col-box-list {
		margin-right: -10px;
		margin-left: -10px;
	}

	.col-box{
		@extend .col-sm-6;
		@extend .col-lg-4;
		padding-right: 10px;
		padding-left: 10px;
		margin-bottom: 30px;
		margin-top: 30px;
		a{
			height: 100%;
			display: flex;
			flex-direction: column;
			text-decoration: none;
			position: relative;
			img{
				@extend %img-responsive;
			}
			strong{
				font-weight: 700;
				font-size: 24px;
				color: #cd9b3c;
				display: block;
				padding: 20px 20px 20px 20px;
				border-bottom: 1px solid #E2E2E2;
			}
			p{
				padding-left: 26px;
				padding-right: 26px;
				color: black;
				font-weight: 500;
				margin-top: 19px;
				margin-bottom: 19px;
			}
			.bt{
				margin-top: auto;
				display: flex;
				justify-content: center;
				align-items: center;
				border-top: 1px solid #E2E2E2;
				border-bottom: 4px solid #00793a;
				color: #00793a;
				padding: 26px 0;
				font-weight: 600;
				text-transform: uppercase;
				transition: all .5s;
				letter-spacing: 1px;
				i{
					font-size: 13px;
					margin-left: 10px;
				}
			}

			&:hover .bt {
				background-color: #00793a;
				color: #fff;
			}
		}

		&__content {
			flex: 1;
			display: flex;
			flex-direction: column;
			position: relative;
			z-index: 1;
			margin: -43px 15px 0;
			background-color: #fff;
			box-shadow: 0 0 30px rgba(#000, .07);
		}
	}
	#slider{
		padding-left: 50px;
		padding-right: 50px;
		background-color: #a45524;
		width: 100%;
		.owl-nav{
			position: absolute;
			top: 40%;
			width: 100%;
			.owl-prev,.owl-next{
				color: white;
				font-size: 40px;
				position: absolute;
				left: -35px;
			}
			.owl-prev{
				transform: rotate(180deg);
			}
			.owl-next{
				left: auto;
				right: -35px;
			}
		}
		.owl-item{
			position: relative;
			&:hover{
				.hover{
					opacity: 1;
				}
			}
			.hover{
				display: table;
				width: 100%;
				text-align: center;
				color: white;
				font-weight: bold;
				vertical-align: middle;
				line-height: normal;
				position: absolute;
				min-height: 300px;
				background-color: rgba(164,85,36,.6);
				width: 100%;
				text-align: center;
				font-size: 16px;
				opacity: 0;
				@media screen and (max-width:576px) {
					height: 480px;
				}
				span.tex{
					display: table-cell;
					vertical-align: middle;
				}
				span.btn{
					border: none;
					background-color: white;
					font-weight: bold;
					padding: 10px;
					border-radius: 60px;
					color: white;
					text-decoration: none;
					text-transform: uppercase;
					display: block;
					width: 60%;
					margin-left: auto;
					margin-right: auto;
					margin-top: 15px;
					font-size: 14px;
					color: #a45524;
					i{
						font-size: 8px;
						margin-left: 3px;
					}
				}
			}
		}
	}
	#newsLetter{
		background-image: url(../images/newsLetterBg.jpg);
		background-repeat: no-repeat;
		background-position: center;
		width: 100%;
		padding-top: 40px;
		padding-bottom: 40px;
		.socials{
			text-align: right;
			padding-top: 15px;
			img{
				margin-left: 20px;
				height: 100px;
			}
		}
		h2{
			font-size: 30px;
			color: white;
			margin: 0px;
			margin-bottom: 15px;
		}
		.block-input{
			background-color: white;
			border-radius: 40px;
			line-height: 40px;
			padding-left: 15px;
			padding-right: 5px;
			button{
				@extend .pull-right;
				border: none;
				background: none;
				background-color: #006E2D;
				border-radius: 60px;
				line-height: 30px;
				margin-top: 4px;
				color: white;
				font-weight: bold;
				padding-left: 20px;
				padding-right: 20px;
			}
			input[type="text"]{
				line-height: 40px;
				border: none;
				background: none;
				width: 60%;
			}
		}
		@media screen and (max-width:980px) {
			.socials{
				display: block;
				width: 100%;
				text-align: center;
				img{
					margin-top: 25px;
				}
			}
			h2{
				text-align: center;
			}
		}
	}
	#alertOrder{
		@extend .col-md-12;
		text-align: center;
		font-weight: bold;
		background-color: #be0900;
		border-radius: 8px;
		color: white;
		padding: 10px;
		display: none;
		margin-top: 15px;
		&.active{
			display: block;
		}
	}

	.offer-list-heading {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media (min-width: 992px) {
			width: 91.66667%;
		}

		h4 {
			margin-top: 0;
		}

		&__icon {
			margin-left: 20px;
		}
	}

	.offer-list-highlighted {
		border: 1px solid rgba(0, 201, 167, 0.2);
		background-color: rgba(0, 201, 167, 0.1);
		padding: 10px;
		margin-right: -10px;
		margin-left: -10px;
		margin-top: 20px;
		margin-bottom: 20px;

		@media (min-width: 992px) {
			width: calc(91.66667% + 20px);

			.offer-list-heading,
			.offer-list {
				width: 100%;
			}
		}

		.offer-list .hiddens {
			margin-top: 0;
			padding-top: 0;
			border-top: none;
			background-color: transparent;
		}

		.offer-list ul:nth-last-child(2) li {
			border-bottom: none;
			padding-bottom: 0;
		}
	}

	.offer-list{
		@extend .col-md-11;
		ul{
			list-style: none;
			width: 100%;
			display: table;
			li{
				display: table-cell;
				text-align: left;
				vertical-align: middle;
				padding-left: 0px;
				border-bottom: 1px dashed #cacaca;
				padding-top: 3px;
				padding-bottom: 13px;
				&:before{
					content: none;
				}
				&:nth-child(1){
					font-size: 15px;
					color: rgba(0, 0, 0, .32);
					width: 8.33333%;
					position: relative;
					min-height: 1px;
				}
				&:nth-child(2){
					font-size: 20px;
					font-size: 15px;
					width: 72%;
					min-height: 1px;
					small{
						display: block;
					}
				}
				&:nth-child(3){
					width: 19.66667%;
					min-height: 1px;
					text-align: right;
					font-weight: 700;
					font-size: 17px;
				}
			}
		}
		.hiddens{
			display: none;
			color: black;
			font-size: 13px;
			font-weight: bold;
			border-top: 1px solid #006E2D;
			width: 100%;
			background-color: white;
			position: relative;
			margin-top: -30px;
			padding: 5px;
			&.active{
				display: block;
			}
			input{
				margin-left: 10px;
				padding: 10px;
				display: inline-block;
				text-align: center;
				width: 50px;
			}
			.button{
				display:inline-block;
				width: 40px;
				text-align: center;
				background-color: #006E2D;
				color: white;
				border: 1px solid white;
				line-height: 35px;
				cursor: pointer;

			}
		}
	}
	.info{
		padding: 10px;
		padding-top: 10px;
		border-radius: 8px;
		border: 1px solid rgba(0, 201, 167, 0.2);
		color: black;
		background-color: rgba(0, 201, 167, 0.1);
		line-height: 20px;
		padding-bottom: 10px;
		strong{
			color: #006E2D;
			font-size: 22px;
			display: block;
			padding-top: 10px;
		}
		span{
			display: block;
			padding-top: 5px;
			font-size: 13px;
			padding-left: 55px;
			strong{
				color: black;
				font-size: 20px;
				padding-top: 0px;
			}
			a{
				color: #006E2D;
				text-decoration: none;
				margin-left: 5px;
				margin-right: 5px;
				border-bottom: 1px solid #006E2D;
			}
		}
		img{
			@extend .pull-left;
			width: 40px;
			margin-right: 15px;
			margin-top: 20px;
		}
	}
	#orderForm{
		padding-top: 15px;
		padding-bottom: 15px;
		@media screen and (max-width:980px) {
			text-align: left;
		}
		.help-block{
			display: none;
		}

		.control-group.error{
			label{
				color: red;
			}
			input[type="text"],input[type="email"],textarea{
				border: 1px solid red;
			}
			.icheckbox_minimal-red{
				border: 1px solid red;
			}
		}
		label{
			display: block;
			padding-left: 15px;
			padding-bottom: 5px;
			font-weight: bold;
			font-size: 13px;
		}
		.radio{
			margin-bottom: 10px;
			font-weight: bold;
			.iradio_minimal-red{
				margin-right: 10px;
			}
			small{
				font-weight: 100;
				display: block;
				padding-left: 30px;
			}
		}

		input[type="text"],input[type="email"],textarea{
			color: black;
			border: none;
			background-color: white;
			border-radius: 4px;
			line-height: 30px;
			padding-top: 5px;
			padding-bottom: 5px;
			padding-left: 15px;
			padding-right: 5px;
			width: 100%;
			margin-bottom: 10px;
			border: 1px solid rgba(0,0,0,.2);
		}
		textarea{
			color: black;
			width: 100%;
			margin-bottom: 10px;
			padding: 15px;
			border: 1px solid white;
			height: 120px;
			border: 1px solid rgba(0,0,0,.2);
		}
		.checkboxs{
			line-height: 18px;
			margin-bottom: 15px;
			.icheckbox_minimal-red{
				margin-right: 10px !important;
				margin-top: 0px !important;
				margin-bottom: 15px !important;
				float: left;
			}
			.checkbox{
				margin-left: 15px;
				margin-right: 15px;
			}
			small{

			}
		}
		/*
		p{
			display: none;
		}
		*/
		/*
		span{
			display: block;
			float: right;
			font-size: 11px;
		}
		*/
		button{
			margin-right: 10px;
			width: 100%;
		}
		.text{
			i{
				font-size: 12px;
				line-height: 20px;
			}
		}
	}
	#footerBlock{
		@extend .container-fluid;
		background-color: #006E2D;
		color: white;

		.contactList{
			padding-top: 15px;
			a{
				color: white;
				text-decoration: none;
			}
			@media screen and (max-width:980px) {
				&{
					text-align: center;
				}
			}
		}
		h2{
			color: white;
			font-size: 30px;
			color: white;
			margin: 0px;
			margin-bottom: 15px;
		}
	}

	.contactForm{
		padding-top: 60px;
		padding-bottom: 60px;
		.control-group.error{
			label{
				color: red;
			}
			input[type="text"],input[type="email"],textarea{
				border: 1px solid red;
			}
			.icheckbox_minimal-red{
				border: 1px solid red;
			}
		}
		label{
			display: block;
			padding-left: 15px;
			padding-bottom: 5px;
		}
		input[type="text"],input[type="email"],textarea{
			color: black;
			border: none;
			background-color: white;
			border-radius: 40px;
			line-height: 30px;
			padding-top: 5px;
			padding-bottom: 5px;
			padding-left: 15px;
			padding-right: 5px;
			width: 100%;
			margin-bottom: 10px;
			border: 1px solid #333;
		}
		textarea{
			color: black;
			width: 100%;
			margin-bottom: 10px;
			padding: 15px;
			border: 1px solid #333;
			height: 120px;
			border-radius: 20px;
		}
		.checkboxs{
			line-height: 18px;
			margin-bottom: 15px;
			.icheckbox_minimal-red{
				margin-right: 10px !important;
				margin-top: 10px !important;
				margin-bottom: 15px !important;
				float: left;
			}
			.checkbox{
				margin-left: 15px;
				margin-right: 15px;
			}
			small{

			}
		}
		p{
			display: none;
		}
		span{
			display: block;
			float: right;
			font-size: 11px;
		}
		button{
			margin-right: 10px;
		}
		.text{
			i{
				font-size: 12px;
				line-height: 20px;
			}
		}
	}

	#footer{
		@extend .container;
		padding-top: 10px;
		padding-bottom: 10px;
		a{
			color: black;
			text-decoration: none;
			&:hover{
				text-decoration: underline;
			}
		}
		img{
			max-height: 15px;

		}
		@media screen and (max-width:980px) {
			&{
				text-align: center;
			}
			p{
				float: none;
				width: 100%;
			}
		}
	}
	/* START modal options */
	.modal{
		background-color: rgba(0,0,0,0.7);
		width: 100%;
		min-height: 100vh;
		position: absolute;
		z-index: 999;
		top: 0px;
		display: none;
		color: black;
		padding-top: 20px;
		&.active{
			display: block;
		}
		.modal-body{
			margin-top: 100px;
			padding-bottom: 15px;
			form{
				input[type="text"],input[type="email"],textarea{
					background-color: white;
					font-size: 12px;
					line-height: 38px;
					color: #000;
					border: 1px solid rgba(0,0,0,.3);
				}
				.error{
					input[type="text"],input[type="email"],textarea{
						border: 1px solid red;
					}
				}
			}
		}
		a.close-modal{
			position: absolute;
			right: 30px;
			z-index: 999;
			font-size: 20px;
			margin-top: 15px;
			width: 50px;
			height: 50px;
			line-height: 50px;
			border-radius: 50px;
			background-color: white;
			text-align: center;
			display: block;
			cursor: pointer;
			&:hover{
				background-color: black;
				color: white;
			}
		}

	}
	/* END modal options */
	.cartForm{
		padding-top: 60px;
		padding-bottom: 60px;
		h2,h3,h4,h5{
			font-weight: bold;
			color: #006E2D;
		}
		h3 {
			line-height: 1.2;
		}
		.header{
			h2{
				text-align: center;
				font-size: 25px;
				padding-top: 15px;
				padding-bottom: 15px;
			}
		}
		ul.title,ul.item,ul.prices{
			margin: 0;
			padding: 0;
			list-style:none;
			li::before{
				display: none;
			}
			li.clearfix{
				width: 0px;
				height: 0px;
				padding: 0px;
				line-height: 0px;
				margin: 0px;
			}
		}
		.title{
			background: #fff;
			background: linear-gradient(to bottom, #fff 0, #efefef 100%);
			li{
				font-weight: bold;
				line-height: 20px;
				margin: 0px;
				padding: 0px;
				padding-top: 15px;
				padding-bottom: 15px;
				padding-left: 5px;
				padding-right: 5px;
				text-align: left;
				&.text-right{
					text-align: right;
				}
				&.text-center{
					text-align: center;
				}

			}
		}
		.item{
			border-bottom: 1px dashed rgba(0,0,0,.2);
			li{
				padding: 20px 5px;
				img{
					width: 100%;
				}
				big{
					font-weight: bold;
					font-size: 15px;
					color: #006E2D;
				}

				&:first-child{
					line-height: 20px;
					margin: 0px;
				}
			}
		}
		hr{
			border: none;
			border-bottom: 1px dashed rgba(0,0,0,.2);
		}
		.prices{
			li{
				font-size: 13px;
				line-height: 30px;
				padding: 0px;
				margin: 0px;
				padding-top: 5px;
				padding-bottom: 5px;
				&.big{
					font-size: 18px;
					font-weight: bold;
				}
			}
		}
		.footer{
			button{
				border: 2px solid #006E2D;
				font-weight: 700;
				padding: 15px;
				border-radius: 60px;
				color: #fff;
				background-color: #006E2D;
			}
			a{
				border: 2px solid #006E2D;
				font-weight: 700;
				padding: 15px;
				border-radius: 60px;
				color: #006E2D;
				background-color: white;
				text-decoration: none;
			}
		}
		.b-r{
			border-right: 1px dashed rgba(0,0,0,.2);
		}
		.checkboxForm{
			font-size: 13px;
			margin-top: 15px;
			margin-bottom: 15px;
			line-height: 16px;
			.icheckbox_minimal-red{
				margin-right: 15px;
			}
			a{
				color: #006E2D;
			}
			i{
				margin-left: 35px;
			}
		}
	}
}

.row.display-flex {
	display: flex;
	flex-wrap: wrap;
	&::before{
		content: "";
		display: none;
	}
}
.row.display-flex > [class*='col-'] {
	display: flex;
	flex-direction: column;
	float: none;
}

/* iCheck plugin Minimal skin, red
----------------------------------- */
.icheckbox_minimal-red,
.iradio_minimal-red {
	display: inline-block;
	*display: inline;
	vertical-align: middle;
	margin: 0;
	padding: 0;
	width: 18px;
	height: 18px;
	background: url(../images/red.png) no-repeat;
	border: none;
	cursor: pointer;
	background-color: white;
}

.icheckbox_minimal-red {
	background-position: 0 0;
}
.icheckbox_minimal-red.hover {
	background-position: -20px 0;
}
.icheckbox_minimal-red.checked {
	background-position: -40px 0;
}
.icheckbox_minimal-red.disabled {
	background-position: -60px 0;
	cursor: default;
}
.icheckbox_minimal-red.checked.disabled {
	background-position: -80px 0;
}

.iradio_minimal-red {
	background-position: -100px 0;
}
.iradio_minimal-red.hover {
	background-position: -120px 0;
}
.iradio_minimal-red.checked {
	background-position: -140px 0;
}
.iradio_minimal-red.disabled {
	background-position: -160px 0;
	cursor: default;
}
.iradio_minimal-red.checked.disabled {
	background-position: -180px 0;
}

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (min-resolution: 1.25dppx) {
	.icheckbox_minimal-red,
	.iradio_minimal-red {
		background-image: url(../images/red@2x.png);
		-webkit-background-size: 200px 20px;
		background-size: 200px 20px;
	}
}
.slider-handle{
	background: #ad0006; /* Old browsers */
	background: -moz-linear-gradient(top, #ad0006 0%, #ff0009 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #ad0006 0%,#ff0009 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #ad0006 0%,#ff0009 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ad0006', endColorstr='#ff0009',GradientType=0 );
}
.slider-selection{
	background: none;
	background-color: #ff0009;
}

@media screen and (max-width: 959px) {
	.visibles-md{
		display: none !important;
	}
}
@media screen and (min-width:980px) {
	.visibles-md {
		display: block
	}

	.visibles-sm {
		display: none
	}
}

@media screen and (max-width:980px) {
	.visibles-md {
		display: none !important
	}

	.visibles-sm {
		display: block !important
	}
}

@media screen and (max-width:812px) {
	.mapDescription{
		.texts{
			img{
				display: none;
			}
		}
	}
}

.footer {
	background-color: #006E2D;
	color: #fff;
	position: relative;
	overflow: hidden;
	padding-bottom: 60px;

	@media (min-width: 992px) {
		&::before {
			content: '';
			display: block;
			position: absolute;
			top: -50%;
			right: -50%;
			bottom: -50%;
			width: 100%;
			background-color: #F8F8F8;
			transform: skewX(-12deg);
			z-index: 1;
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 228px;
			left: calc(50% + 333px);
			background-image: url('../images/flower.svg');
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			width: 798px;
			height: 478px;
			z-index: 2;
		}
	}

	.container {
		position: relative;
		z-index: 3;
	}

	h2 {
		color: #fff;
	}

	.btn {
		display: inline-block;
	}
}

.vouchers {
	&__content {
		padding-top: 76px;
	}

	&__title {
		display: inline-flex;
		align-items: center;
		font-size: 26px;
		color: #CD9B3C;
		text-transform: uppercase;

		&::after {
			content: '';
			display: block;
			width: 103px;
			height: 3px;
			background-color: currentColor;
			margin-left: 23px;
		}
	}

	&__heading {
		text-transform: uppercase;
		font-size: 56px;
		font-weight: 600;
		line-height: 1.16;
		margin-top: 9px;
		margin-bottom: 36px;
	}

	&__image {
		display: flex;
		justify-content: flex-end;

		img {
			margin-top: -12px;
			margin-right: -48px;
			margin-bottom: -32px;

			@media (max-width: 1199.98px) {
				max-width: 135%;
				margin-bottom: 20px;
			}

			@media (max-width: 991.98px) {
				max-width: 100%;
				margin: 0;
				position: relative;
				left: -7%;
			}
		}
	}
}

.quick-contact {
	&__content {
		font-size: 18px;

		@media (max-width: 991.98px) {
			margin-bottom: 40px;
		}

		.text-large {
			font-size: 26px;
		}

		b {
			color: #CD9B3C;
		}

		p {
			margin-bottom: 44px;
			font-weight: 500;

			a {
				color: #fff;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	&__heading {
		font-size: 36px;
		font-weight: 600;
		text-transform: uppercase;
		margin-top: 0;
		margin-bottom: 25px;
	}

	&__image {
		display: flex;
		justify-content: flex-end;
		line-height: 0;

		&__wrap {
			position: relative;

			&::before {
				@media (min-width: 992px) {
					content: '';
					display: block;
					background-image: url('../images/points.svg');
					background-position: center;
					background-repeat: no-repeat;
					background-size: contain;
					width: 348px;
					height: 357px;
					position: absolute;
					top: -46px;
					right: -48px;
					z-index: 1;
				}
			}

			img	{
				position: relative;
				z-index: 2;

				@media (max-width: 1199.98px) {
					max-width: 100%;
				}
			}
		}
	}
}

.load-more {
	position: relative;
	z-index: 2;
	margin-top: -150px;
	margin-bottom: -40px;
	padding-bottom: 110px;

	&::before {
		content: '';
		display: block;
		z-index: -1;
		position: absolute;
		top: -144px;
		right: 0;
		left: 0;
		bottom: 0;
		background: linear-gradient(180deg, rgba(#fff, 0), rgba(#fff, .85) 10%, rgba(#fff, .99), #fff);
	}
}

.filter {
	background-color: #fff;
	box-shadow: 0 0 30px rgba(#000, .07);
	padding: 15px;
	margin-top: 32px;
	margin-bottom: 32px;

	@media (min-width: 576px) {
		padding: 24px;
	}

	@media (min-width: 992px) {
		padding-right: 47px;
		padding-left: 47px;
	}

	&__heading {
		display: flex;
		justify-content: center;
		align-items: center;
		text-transform: uppercase;
		font-weight: 600;
		text-align: center;
		font-size: 20px;
		line-height: 1.2;

		&__icon {
			width: 22px;
			height: 20px;
			fill: currentColor;
			margin-left: 11px;
		}
	}

	hr {
		border: none;
		border-bottom: 1px solid #DBDBDB;
		margin: 24px 0;
	}

	&__list {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		margin: -10px 0;

		@media (min-width: 992px) {
			margin: -20px 0;
		}

		&__divider {
			flex: 0 0 100%;
			max-width: 100%;

			@media (min-width: 992px) {
				display: none;
			}
		}

		.form-group {
			margin: 10px;

			@media (min-width: 992px) {
				margin: 20px;
			}

			@media (max-width: 575.98px) {
				flex: 0 0 100%;
				max-width: 100%;
				margin-right: 0;
				margin-left: 0;
			}

			&.checkboxs {
				@media (max-width: 575.98px) {
					flex: 0 0 50%;
					max-width: 50%;
				}
			}

			.custom-select {
				@media (max-width: 575.98px) {
					width: 100%;
				}
			}
		}
	}
}

.sorting {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-bottom: -15px;

	&__item {
		color: #D1D1D1;
		font-weight: 600;
		text-transform: uppercase;
		font-size: 14px;
		text-decoration: none;
		margin: 10px;

		&:hover,
		&.active {
			color: #006E2D;
		}
	}
}

.img-shadow {
	box-shadow: 0 0 30px rgba(#000, .2);
}

.checkboxs label {
	cursor: pointer;

	small {
		margin-left: 10px;
		font-size: 14px;
	}
}

.custom-select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: 1px solid #D1D1D1;
	background-color: #fff;
	color: #000;
	font-weight: 600;
	font-size: 14px;
	padding: 12px 24px;
	min-width: 180px;
	border-radius: 25px;
	cursor: pointer;
	background-image: url('../images/select-arrow.svg');
	background-position: center right 20px;
	background-size: 9px 17px;
	background-repeat: no-repeat;

	&:focus,
	&:focus-visible {
		background-image: url('../images/select-arrow-open.svg');
		background-size: 17px 9px;
		background-color: #F7F7F7;
		outline: none;
	}
}

.product-detail {
	font-weight: 500;

	@media (min-width: 768px) {
		font-size: 18px;
	}

	.row {
		@media (min-width: 1200px) {
			> div {
				&:first-child {
					padding-right: 40px;
				}

				&:last-child {
					padding-right: 61px;
					padding-left: 40px;
				}
			}
		}
	}

	&__header {
		padding-top: 48px;
		padding-bottom: 58px;

		.row {
			@media (min-width: 1200px) {
				display: flex;
				align-items: center;
			}
		}
	}

	&__description {
		padding-top: 88px;
		padding-bottom: 108px;
		margin-bottom: -35px;

		.row:first-child {
			margin-bottom: 53px;
		}
	}
}

.grayBg {
	background-color: #F8F8F8;
}

.product-gallery {
	max-width: 484px;
	margin: 0 auto;

	@media (max-width: 1199.98px) {
		margin-top: 30px;
	}

	&__big {
		margin-bottom: 20px;
	}

	&__small {
		padding: 0 23px;

		&__item {
		}
	}

	.product-gallery-item {
		position: relative;
		display: block;
	}

	.photo {
		padding-top: 0;
		padding-bottom: 0;
	}

	.owl-prev,
	.owl-next {
		position: absolute;
		top: calc(50% - 11px);
		font-size: 22px;
		color: #D1D1D1;

		&:hover {
			color: #006E2D;
		}

		&.disabled {
			display: none;
		}
	}

	.owl-prev {
		left: -22px;
	}

	.owl-next {
		right: -22px;
	}
}

.checked-list ul {
	padding-left: 0;

	li {
		display: flex;
		align-items: center;
		position: relative;
		list-style-type: none;
		padding-left: 0;
		border-bottom: 1px solid #DBDBDB;
		padding-top: 1.5em;
		padding-bottom: 1.5em;

		&:last-child {
			border-bottom: none;
		}

		&::before {
			content: '';
			display: block;
			width: 24px;
			height: 18px;
			background-image: url('../images/check.svg');
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			margin-right: 20px;
		}
	}
}

.heading {
	margin-top: 0;
	font-size: 28px;
	font-weight: 600;
	border-bottom: 3px solid currentColor;
	padding-bottom: 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-transform: uppercase;

	@media (min-width: 576px) {
		font-size: 36px;
	}

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.3889em;
		width: 1em;
		height: 1em;

		svg {
			width: 100%;
			height: 100%;
		}
	}
}

.price-box {
	background-color: #fff;
	box-shadow: 0 0 30px rgba(#000, .1);
	text-align: center;
	padding-top: 29px;
	padding-bottom: 47px;
	margin-top: 20px;

	&__heading {
		font-weight: 600;
		border-bottom: 1px solid #DBDBDB;
		padding-bottom: 30px;
		margin: 0 0 41px;
		font-size: 28px;
		color: #CD9B3C;

		@media (min-width: 576px) {
			font-size: 38px;
		}
	}

	&__price {
		display: block;
		font-size: 48px;
		font-weight: 600;
		color: #006E2D;
		margin-bottom: 26px;
		line-height: 1.2;

		@media (min-width: 576px) {
			font-size: 68px;
		}
	}

	p {
		margin: 0;
		font-weight: 600;
	}

	.btn {
		display: inline-block;
		min-width: 250px;
	}
}

.progress-bar {
	background-color: #fff;
	box-shadow: 0 0 30px rgba(#000, .07);
	border-radius: 27px;
	display: flex;
	margin-top: 44px;

	&__title {
		background-color: #CD9B3C;
		color: #fff;
		font-weight: 600;
		border-radius: 27px;
		display: inline-block;
		padding: 15px 0 15px 44px;

		@media (max-width: 575.98px) {
			padding-left: 20px;
		}
	}

	&--30 .progress-bar__title {
		width: 33.33%;
	}

	&--60 .progress-bar__title {
		width: 50%;
	}

	&--90 .progress-bar__title {
		width: 75%;
	}

	&--120 .progress-bar__title {
		width: 100%;
	}
}

.product-boxes {
	margin-bottom: 70px;

	.row {
		@media (min-width: 992px) {
			display: flex;
			margin: 0;

			> div {
				padding: 0;
				display: flex;
				flex: 0 0 50%;
				max-width: 50%;
			}
		}
	}
}

.contact-box {
	background-color: #006E2D;
	color: #fff;
	width: 100%;
	padding: 30px 30px;
	font-size: 18px;
	font-weight: 500;
	position: relative;

	@media (min-width: 576px) {
		padding: 40px 40px;
	}

	@media (min-width: 1200px) {
		padding: 40px 65px;
	}

	&--gold {
		background-color: #CD9B3C;

		@media (min-width: 576px) {
			padding-right: 190px;
		}

		@media (min-width: 1200px) {
			padding-right: 250px;
		}
	}

	&--green {
		p {
			margin-bottom: 44px;
		}
	}

	&__heading {
		color: #fff;
		font-size: 28px;
		font-weight: 600;
		text-transform: uppercase;
		margin-top: 0;
		margin-bottom: 23px;
		line-height: 1.2;

		@media (min-width: 576px) {
			font-size: 36px;
		}
	}

	&__item {
		display: inline-flex;
		align-items: center;
		text-decoration: none;
		margin-top: 7px;
		margin-bottom: 7px;

		&__icon {
			flex: 0 0 33px;
			max-width: 33px;
		}

		&__value {
			flex: 1;
			white-space: nowrap;
		}
	}

	&__image {
		width: 240px;
		height: 240px;
		box-shadow: 0 0 30px rgba(#000, .1);
		display: block;
		margin: 0 auto 30px;

		@media (min-width: 576px) {
			position: absolute;
			top: 25px;
			right: -55px;
		}

		@media (min-width: 992px) {
			top: 140px;
		}

		@media (min-width: 1200px) {
			width: 270px;
			height: 270px;
			top: 40px;
			right: -45px;
		}
	}

	.btn {
		display: inline-block;
	}

	p {
		margin-bottom: 33px;
	}

	a {
		color: #fff;
	}
}

.image-popup {
	position: relative;
	width: auto;
	max-width: 800px;
	margin: 20px auto;
}

@media (max-width: 575.98px) {
	.image-popup .mfp-close {
		width: 30px;
		height: 30px;
		font-size: 20px;
	}
}

.hidden-desktop {
	@media (min-width: 1196px) {
		display: none !important;
	}
}

body #head #nav .nav li {
	padding-left: 20px;

	a {
		@media (max-width: 1399.98px) {
			font-size: 14px;
		}

		@media (max-width: 1229.98px) {
			font-size: 13px;
		}
	}
}

@media (max-width: 1195px) {
	body #head #nav a.mobile-btn {
		z-index: 10;
	}

	body #head #nav .nav {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: 9;
		overflow: auto;

		li {
			padding-left: 0;
			text-align: center;
			line-height: 50px;

			a {
				font-size: 20px;
			}
		}
   }
}

body #head #nav .cta-buttons {
	float: right;
	display: flex;
	flex-direction: column;

	@media (max-width: 1195px) {
		align-items: center;
		float: none;
		row-gap: 20px;
		margin-top: 20px;
	}

	&.cta-buttons--desktop {
		@media (max-width: 1195px) {
			display: none;
		}
	}

	.btn {
		padding: 10px 15px;
		font-size: 14px;
		text-align: center;
		margin-top: 10px;

		@media (max-width: 1195px) {
			margin: 0 !important;
			padding: 8px 30px;
		}
	}
}

html.has-open-menu {
	@media (max-width: 1195px) {
		overflow: hidden;
	}
}