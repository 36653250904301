@font-face {
	font-family: 'iconpack';
	src:  url('../fonts/iconpack.eot?bhj6br');
	src:  url('../fonts/iconpack.eot?bhj6br#iefix') format('embedded-opentype'),
	url('../fonts/iconpack.ttf?bhj6br') format('truetype'),
	url('../fonts/iconpack.woff?bhj6br') format('woff'),
	url('../fonts/iconpack.svg?bhj6br#iconpack') format('svg');
	font-weight: normal;
	font-style: normal;
}
i.icon{
	font-family: 'iconpack' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&.icon-close:before {
		content: "\e90d";
	}
	&.icon-menu:before {
		content: "\e90e";
	}
	&.icon-mail:before {
		content: "\e909";
	}
	&.icon-arrow-left:before {
		content: "\e903";
	}
	&.icon-arrow-right:before {
		content: "\e905";
	}
	&.icon-arrow-top:before {
		content: "\e906";
	}
	&.icon-home:before {
		content: "\e907";
	}
	&.icon-right:before {
		content: "\e908";
	}
	&.icon-clock-o:before {
		content: "\e90a";
	}
	&.icon-mobile:before {
		content: "\e90b";
	}
	&.icon-envelope:before {
		content: "\e90c";
	}
	&.icon-instagram:before {
		content: "\e902";
	}
	&.icon-facebook:before {
		content: "\e904";
	}
	&.icon-phone:before {
		content: "\e942";
	}
}
