// Responsive utilities

//
// More easily include all the states for responsive-utilities.less.
// [converter] $parent hack
@mixin responsive-visibility($parent) {
	#{$parent} {
		display: block !important;
	}
	table#{$parent}  { display: table !important; }
	tr#{$parent}     { display: table-row !important; }
	th#{$parent},
	td#{$parent}     { display: table-cell !important; }
}

// [converter] $parent hack
@mixin responsive-invisibility($parent) {
	#{$parent} {
		display: none !important;
	}
}
